
import { RigidBody, CuboidCollider } from "@react-three/rapier"
import { Box, Text } from "@react-three/drei"




export function ClaimBoard() {

    return (
        <>
            <RigidBody type="fixed" colliders={false} position={[0, -0.25, 0]}>
                <Box receiveShadow args={[0.125, 0.5, 0.125]} position={[1.25, 0, 0]}>
                    <meshStandardMaterial color={"rgb(50,34,11)"} />
                </Box>
                <Box receiveShadow args={[0.125, 0.5, 0.125]} position={[-1.25, 0, 0]}>
                    <meshStandardMaterial color={"rgb(50,34,11)"} />
                </Box>
                <Box receiveShadow args={[3, 1.0, 0.125]} position={[0, 0.75, 0]}>
                    <meshStandardMaterial color={"rgb(50,34,11)"} />
                </Box>
                <Text fontSize={0.5} position={[0, 0.75, 0.07]}>
                    Claim Land
                </Text>
                <CuboidCollider args={[1.5, 1.25, 0.25]}  />
            </RigidBody>
        </>
    )
}
