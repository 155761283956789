import React from 'react';
import './root.css';

function Root() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
    </div>
  );
}

export default Root;
