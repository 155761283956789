import * as THREE from "three"

import { useState, useEffect, Suspense, useMemo, ReactElement } from 'react';
// import useWebSocket from 'react-use-websocket';

import { Canvas } from '@react-three/fiber';
import { Physics } from '@react-three/rapier';
import { EffectComposer, SMAA } from '@react-three/postprocessing'
import { KeyboardControls, KeyboardControlsEntry, PointerLockControls } from '@react-three/drei';

import Ground from './Ground';
import { Player, Controls } from './Player';
import { Land } from './Land';
import { Perf } from 'r3f-perf';

import {TargetBlock} from './TargetBlock';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function World() {

    const queryClient = new QueryClient();

    const [focusedLocation, setFocusedLocation] = useState<THREE.Vector2>(new THREE.Vector2(0, 0));

    const controlMap = useMemo<KeyboardControlsEntry<Controls>[]>(() => [
        { name: Controls.forward, keys: ['ArrowUp', 'KeyW'] },
        { name: Controls.back, keys: ['ArrowDown', 'KeyS'] },
        { name: Controls.left, keys: ['ArrowLeft', 'KeyA'] },
        { name: Controls.right, keys: ['ArrowRight', 'KeyD'] },
        { name: Controls.jump, keys: ['Space'] },
    ], [])

    // const [socketUrl] = useState(`wss://localhost/worlds/1`);
    // const { sendJsonMessage, lastJsonMessage } = useWebSocket(
    //     socketUrl, {
    //     shouldReconnect: (closeEvent) => true,
    // }
    // );

    const lastJsonMessage = ""
    const sendJsonMessage = (msg: string) => { };

    useEffect(() => {
        // let msg = Object.assign(new RpsGameMessage(), lastJsonMessage);

        // let msg = lastJsonMessage;

        // TODO: switch to useEffectEvent when it is no longer experimental api
        // eslint-disable-next-line react-hooks/exhaustive-deps
        console.log("Received message: ", lastJsonMessage);
    }, [lastJsonMessage]);

    const plots = useMemo<Array<ReactElement>>(() => {
        let plots: Array<ReactElement> = [];

        // const specialLand = new Map<string, string>([
        //     ["land[1][1]", "bobross"],
        //     ["land[1][-1]", "artosis"],
        //     ["land[0][-1]", "AdamCYounis"],
        //     ["land[0][1]", "Cirno_TV"],
        //     ["land[1][0]", "j_blow"],
        // ]);

        plots.length = 0;
        console.log(`Setting up land: ${focusedLocation.x}, ${focusedLocation.x}`);
        for (let x = -1; x <= 1; x++) {
            for (let y = -1; y <= 1; y++) {
                const landX = focusedLocation.x + x
                const landY = focusedLocation.y + y
                if (landX === 0 && landY === 0) {
                    continue;
                }
                const active = focusedLocation.x === landX && focusedLocation.y === landY

                const key = `land[${landX}][${landY}]`
                // let channel: string = "";
                // if (specialLand.has(key)) {
                //     channel = specialLand.get(key) as string;
                // }

                plots.push(
                    <Land key={key}
                        x={landX} y={landY}
                        active={active} enteredLocation={setFocusedLocation}
                    />
                )
            }
        }

        return plots;
    }, [focusedLocation]);

    return (
        <>
            <QueryClientProvider client={queryClient}>
            <Suspense>
                <KeyboardControls map={controlMap}>
                    <Canvas shadows camera={{ fov: 50}} frameloop='demand'>
                        <Perf position="top-left" />
                        <directionalLight color={[0.3, 0.3, 0.5]} intensity={2*Math.PI} position={[150, 1000, 100]} />
                        <ambientLight intensity={0.1*Math.PI} />
                        <Physics gravity={[0, -30, 0]}>
                            <Ground />
                            <Player sendWorldMsg={sendJsonMessage} />
                            {plots}
                            <TargetBlock/>
                        </Physics>
                        <PointerLockControls />
                        <EffectComposer enableNormalPass={false}>
                            <SMAA />
                        </EffectComposer>
                    </Canvas>
                </KeyboardControls>
            </Suspense>
            </QueryClientProvider>
        </>
    );
}

export default World;
