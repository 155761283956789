
import * as THREE from "three"
import { Ref, useLayoutEffect, useMemo, useRef } from "react"
import { RigidBody, CuboidCollider } from "@react-three/rapier"
import { Box } from "@react-three/drei"


import { LAND_SIZE } from "./Land"


export function Fence() {

    const instancesRef: Ref<THREE.InstancedMesh> = useRef<THREE.InstancedMesh>(null!);

    const posts: Array<THREE.Vector3> = useMemo(() => {
        let posts: Array<THREE.Vector3> = [];

        for (let x = 0; x < 10; ++x) {
            posts.push(new THREE.Vector3(LAND_SIZE/2.0, 0, LAND_SIZE/2.0-x*(LAND_SIZE/9.0)))
            posts.push(new THREE.Vector3(-LAND_SIZE/2.0,0,LAND_SIZE/2.0-x*(LAND_SIZE/9.0)))
            posts.push(new THREE.Vector3(LAND_SIZE/2.0-x*(LAND_SIZE/9.0),0,LAND_SIZE/2.0))
            posts.push(new THREE.Vector3(LAND_SIZE/2.0-x*(LAND_SIZE/9.0),0,-LAND_SIZE/2.0))
        }

        return posts;
    }, []);

    
    useLayoutEffect(() => {
        const tempObj = new THREE.Object3D()

        if (instancesRef === null || instancesRef.current === null) {return;}

        for (let i = 0; i < posts.length; ++i) {
            const pos = posts[i];
            tempObj.position.set(pos.x, pos.y, pos.z);
            tempObj.updateMatrix()
            instancesRef.current.setMatrixAt(i, tempObj.matrix);
        }

        instancesRef.current.instanceMatrix.needsUpdate = true;
        if (instancesRef.current.instanceColor) {
            instancesRef.current.instanceColor.needsUpdate = true;
        }
    }, [posts]);

    return (
        <>
            <RigidBody type="fixed" colliders={false} position={[0, 0.25, LAND_SIZE/2]}>
                <Box receiveShadow args={[LAND_SIZE, 0.25, 0.25]}>
                    <meshStandardMaterial color={"rgb(50,34,11)"} />
                </Box>
                <CuboidCollider args={[LAND_SIZE/2.0, 0.25, 0.25]}  />
            </RigidBody>
            <RigidBody type="fixed" colliders={false} position={[0, 0.25, -LAND_SIZE/2]}>
                <Box receiveShadow args={[LAND_SIZE, 0.25, 0.25]}>
                    <meshStandardMaterial color={"rgb(50,34,11)"} />
                </Box>
                <CuboidCollider args={[LAND_SIZE/2.0, 0.25, 0.25]}  />
            </RigidBody>
            <RigidBody type="fixed" colliders={false} position={[LAND_SIZE/2, 0.25, 0]}>
                <Box receiveShadow args={[0.25, 0.25, LAND_SIZE]}>
                    <meshStandardMaterial color={"rgb(50,34,11)"} />
                </Box>
                <CuboidCollider args={[0.25, 0.25, LAND_SIZE/2.0]}  />
            </RigidBody>
            <RigidBody type="fixed" colliders={false} position={[-LAND_SIZE/2, 0.25, 0]}>
                <Box receiveShadow args={[0.25, 0.25, LAND_SIZE]}>
                    <meshStandardMaterial color={"rgb(50,34,11)"} />
                </Box>
                <CuboidCollider args={[0.25, 0.25, LAND_SIZE/2.0]}  />
            </RigidBody>
            <instancedMesh frustumCulled={false} ref={instancesRef} args={[undefined, undefined, posts.length]} count={posts.length}>
                <meshStandardMaterial color={"rgb(50,34,11)"} />
                <boxGeometry args={[0.25, 1.25, 0.25]}>
                </boxGeometry>
            </instancedMesh>
            {posts}
        </>
    )
}
