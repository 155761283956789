
import * as THREE from "three"
import { ReactElement, Ref, useCallback, useEffect, useMemo, useRef } from "react"
import { InstancedRigidBodies, InstancedRigidBodyProps } from "@react-three/rapier"
import { useTexture } from "@react-three/drei"


import block from "../assets/block.jpg"
import { randInt } from "three/src/math/MathUtils"
import { ThreeEvent } from "@react-three/fiber"
import { usePointerStore } from "./TargetBlock"
import { useQuery } from "@tanstack/react-query"
import { Block, useBlockStore } from "./BlockStore"

export interface BuildingProps {
    landX: number
    landY: number
    x: number
    y: number
    z: number
    active: boolean
}

const testBlockCount = 100;

const tempColor = new THREE.Color("red");


export function Building({landX, landY, x, y, z, active }: BuildingProps) {

    const {data} = useQuery<Block>({
        queryKey: [`land-${landX}-${landY}`],
        queryFn: async () => {
            const response = await fetch(`https://world.pwner.net/blocks/${landX}/${landY}`, {credentials: 'include'});
            const json = await response.json();
            return json;
        },
        placeholderData: {"blockData": {"layers": {}}} as Block
    });

    const setBlock = useBlockStore((state) => state.setBlock);
    const blockData = useBlockStore((state) => state.blocks.get(`[${landX},${landY}]`));

    useEffect(() => {
        if (data !== undefined) {
            setBlock(landX, landY, data);
        }
    }, [data, landX, landY, setBlock]);

    const setEvent = usePointerStore((state) => state.setEvent);

    const onMove = useCallback((event: ThreeEvent<PointerEvent>) => {
        event.stopPropagation()
        setEvent(true, event, x, y, z);
    }, [ x, y, z, setEvent]);

    const onLeave = useCallback((event: ThreeEvent<PointerEvent>) => {
        event.stopPropagation();
        setEvent(false, event, 0, 0, 0);
    }, [setEvent]);


    const instancesRef: Ref<THREE.InstancedMesh> = useRef<THREE.InstancedMesh>(null!);

    const texture = useTexture(block)
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

    const blockTexture = useTexture(block)
    blockTexture.wrapS = blockTexture.wrapT = THREE.RepeatWrapping;

    const instances: InstancedRigidBodyProps[] = useMemo(() => {
        let instances: InstancedRigidBodyProps[] = [];

        let i = 0;

        if (blockData) {
            const layers = blockData.blockData.layers;
    
            for (let layer of Object.keys(layers)) {
                const blocks = layers[layer];
                const y = Number(layer);
    
                for (let [coord, color] of Object.entries(blocks)) {
    
                    const val = Number(coord);
    
                    const z = (val % 64) - 32;
                    const x = Math.floor((val - (z+32)) / 64) - 32;
    
                    instances.push({
                        key: "instance_" + i++,
                        position: new THREE.Vector3(x, y, z),
                        userData: {
                            color: "rgb(" + color + "," + color + "," + color + ")"
                        }
                    });
                }
            }
        }

        for (; i < testBlockCount; ++i) {
            instances.push({
                key: "instance_" + i,
                position: new THREE.Vector3(0, -10, 0),
                userData: {
                    color: "rgb(" + randInt(32, 200) + "," + randInt(32, 200) + "," + randInt(32, 200) + ")"
                }
            });
        }
        return instances;
    }, [blockData]);

    const colorArray = useMemo(() => {
        return Float32Array.from(new Array(instances.length).fill(tempColor).flatMap((_, i) => tempColor.set(instances[i].userData?.color).toArray()));
    }, [instances]);


    useEffect(() => {
        console.log("Updating Block Transforms: ", blockData);
        const tempObj = new THREE.Object3D()

        if (instancesRef === null || instancesRef.current === null) {return;}

        for (let i = 0; i < instances.length; ++i) {
            const pos = instances[i].position as THREE.Vector3;
            if (pos === undefined) {continue;}
            tempObj.position.set(pos.x, pos.y, pos.z);
            tempObj.updateMatrix()
            instancesRef.current.setMatrixAt(i, tempObj.matrix);
        }

        instancesRef.current.instanceMatrix.needsUpdate = true;
        if (instancesRef.current.instanceColor) {
            instancesRef.current.instanceColor.needsUpdate = true;
        }
    }, [active, blockData, instances]);

    let blocks: ReactElement = <></>;
    if (active) {
        blocks = (
            <InstancedRigidBodies type="fixed" instances={instances} colliders={"cuboid"}>
                <instancedMesh frustumCulled={false} ref={instancesRef} args={[undefined, undefined, instances.length]} count={instances.length} onPointerMove={onMove} onPointerLeave={onLeave}>
                    <meshStandardMaterial map={texture} map-repeat={[1, 1]} vertexColors />
                    <boxGeometry args={[1]}>
                        <instancedBufferAttribute attach="attributes-color" args={[colorArray, 3]} />
                    </boxGeometry>
                </instancedMesh>
            </InstancedRigidBodies>
        )
    } else {
        blocks = (
            <instancedMesh frustumCulled={false} ref={instancesRef} args={[undefined, undefined, instances.length]} count={instances.length}>
                <meshStandardMaterial map={texture} map-repeat={[1, 1]} vertexColors />
                <boxGeometry args={[1]}>
                    <instancedBufferAttribute attach="attributes-color" args={[colorArray, 3]} />
                </boxGeometry>
            </instancedMesh>
        );
    }

    return (
        <>
            {blocks}
        </>
    )
}
