import * as THREE from "three"
import * as RAPIER from "@dimforge/rapier3d-compat"
import { RootState, useFrame } from "@react-three/fiber"
import { Stars, useKeyboardControls } from "@react-three/drei"
import { CapsuleCollider, RigidBody, useRapier, RapierRigidBody, vec3 } from "@react-three/rapier"
import { useRef } from "react"

const SPEED = 10
const direction = new THREE.Vector3()
const frontVector = new THREE.Vector3()
const sideVector = new THREE.Vector3()
// const rotation = new THREE.Vector3()



export enum Controls {
    forward = 'forward',
    back = 'back',
    left = 'left',
    right = 'right',
    jump = 'jump',
}

export interface PlayerProps {
    sendWorldMsg: (msg: string) => void;
}


export function Player({sendWorldMsg}: PlayerProps) {

    const ref = useRef<RapierRigidBody>(null!);

    const { world } = useRapier()

    const [, get] = useKeyboardControls<Controls>()


    useFrame((state: RootState) => {
        if (ref.current === null) {
            return;
        }

        const { forward, back, left, right, jump } = get()
        const velocity = ref.current?.linvel()

        // update camera
        const pos = ref.current.translation();
        state.camera.position.set(pos.x, pos.y, pos.z)
        const rot = state.camera.rotation;

        // movement
        frontVector.set(0, 0, (back ? 1.0 : 0.0) - (forward ? 1.0 : 0.0))
        sideVector.set((left ? 1.0 : 0.0) - (right ? 1.0 : 0.0), 0, 0)
        direction.subVectors(frontVector, sideVector).normalize().multiplyScalar(SPEED).applyEuler(rot)
        direction.y = velocity.y

        const new_velocity = vec3(direction);
        ref.current.setLinvel(new_velocity, true);

        const ray = new RAPIER.Ray(ref.current.translation(), {x:0, y:-1, z:0});
        // jumping
        const cast_result = world.castRay(
            ray,
            100.0,
            true,
            RAPIER.QueryFilterFlags.EXCLUDE_SENSORS,
            undefined,
            ref.current.collider(0),
            undefined,
            (collider: RAPIER.Collider) => {
                if (collider.isSensor()) {
                    return false;
                }
                return ref.current.collider(0).handle !== collider.handle;
            }
        );

        const grounded = cast_result && cast_result.collider && Math.abs(cast_result.timeOfImpact) <= 1.5
        if (jump && grounded) {
            ref.current.setLinvel(new RAPIER.Vector3(direction.x, 10, direction.z), true);
        }
    })

    return (
        <RigidBody ref={ref} colliders={false} mass={1} type="dynamic" position={[2, 2, 0]} enabledRotations={[false, false, false]}>
            <CapsuleCollider args={[0.9, 0.2]} />
            <Stars fade factor={6} saturation={0} depth={25.0} speed={0.1} />
        </RigidBody>
    )
}
