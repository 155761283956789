import * as THREE from "three"
import { useTexture } from "@react-three/drei"
import { CuboidCollider, RigidBody } from "@react-three/rapier"
import grass from "../assets/grass.jpg"


function Ground() {
    const texture = useTexture(grass)
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    // texture.colorSpace = THREE.SRGBColorSpace;

    return (
        <RigidBody  type="fixed" colliders={false}>
          <mesh receiveShadow position={[0, 0, 0]} rotation-x={-Math.PI / 2}>
            <planeGeometry args={[10000, 10000]} />
            <meshStandardMaterial map={texture} map-repeat={[2400, 2400]} color="green" />
          </mesh>
          <CuboidCollider args={[10000, 2, 10000]} position={[0, -2, 0]} />
        </RigidBody>
    )
}

export default Ground;
