
import * as THREE from "three"
import { Box } from "@react-three/drei"
import { useRef } from "react"
import { create } from 'zustand'
import { ThreeEvent } from "@react-three/fiber"
import { LAND_SIZE } from "./Land"

export interface PointerState {
    active: boolean
    event: ThreeEvent<PointerEvent>
    x: number
    y: number
    z: number

    setEvent: (active: boolean, event: ThreeEvent<PointerEvent>, base_x: number, base_y: number, base_z: number) => void
}

export const usePointerStore = create<PointerState>((set) => ({
    active: false,
    event: null!,
    x: 0,
    y: 0,
    z: 0,
    setEvent: (active: boolean, event: ThreeEvent<PointerEvent>, base_x: number, base_y: number, base_z: number) => {
        set(() => ({
            active: active,
            event: event,
            x: base_x,
            y: base_y,
            z: base_z
        }))
    },
}));

export function TargetBlock() {

    usePointerStore.subscribe((state) => {
        if (blockRef === null || blockRef.current === null) {
            return;
        }
        
        const intersect = state.event.point;
        const dir = state.event.face?.normal ?? new THREE.Vector3(0,0,0);

        blockRef.current.visible = state.active;
        
        // TODO: Remove news and use a const temp object instead
        const blockCoord = new THREE.Vector3(
            Math.round(intersect.x-state.x+dir.x/2.0),
            Math.round(intersect.y-state.y+dir.y/2.0),
            Math.round(intersect.z-state.z+dir.z/2.0)
        );

        // Prevent from building on top of the fence that surrounds the land
        const LIMIT = LAND_SIZE / 2.0;
        if (blockCoord.x <= -LIMIT || blockCoord.x >= LIMIT || blockCoord.z <= -LIMIT || blockCoord.z >= LIMIT) {
            blockRef.current.visible = false;
            return;
        }

        blockRef.current.position.set(
            state.x + blockCoord.x,
            state.y + blockCoord.y,
            state.z + blockCoord.z
        );
        blockRef.current.updateMatrix();
    })

    const blockRef = useRef<THREE.Mesh>(null!);

    return (
        <Box args={[1,1,1]} ref={blockRef} position={[0,10000,0]}>
            <meshStandardMaterial color="red"/>
        </Box>
    )
}
