
import * as THREE from "three"
import { RigidBody, CuboidCollider } from "@react-three/rapier"
import { Box, useTexture } from "@react-three/drei"

import { Vector2 } from "three"

import dirt from "../assets/dirt.jpg"
import { Fence } from "./Fence"
import { TwitchBillboard } from "./TwitchBillboard"
import { Building } from "./Building"
import { ClaimBoard } from "./ClaimBoard"
import { ThreeEvent } from "@react-three/fiber"
import { useCallback } from "react"
import { usePointerStore } from "./TargetBlock"
import { useQuery } from "@tanstack/react-query"

export interface LandProps {
    x: number
    y: number
    active: boolean
    enteredLocation: (loc: Vector2) => void
}

export const LAND_SIZE = 64.0;
export const PADDING_SIZE = 24;

interface BlockData {
    layers: Record<string, Record<string, number>>;
}

interface Block {
    ownerId: string;
    ownerName: string;
    blockData: BlockData
};

export function Land({ x, y, active, enteredLocation }: LandProps) {

    const setEvent = usePointerStore((state) => state.setEvent);
    
    const texture = useTexture(dirt)
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

    const base_x = x * (LAND_SIZE + PADDING_SIZE);
    const base_y = 0.5;
    const base_z = y * (LAND_SIZE + PADDING_SIZE);

    const {data} = useQuery<Block>({
        queryKey: [`land-${x}-${y}`],
        queryFn: async () => {
            const response = await fetch(`https://world.pwner.net/blocks/${x}/${y}`, {credentials: 'include'});
            const json = await response.json();
            return json;
        },
        placeholderData: {
            "ownerName": ""
        } as Block
    });

    // Land collider to activate it (Twitch channel turns on)
    let landActivator = useCallback(() => {
        if (active || !data || data.ownerName === "") {
            return;
        }

        enteredLocation(new Vector2(x, y));
    }, [active, data, x, y, enteredLocation]);

    // Mouse hover on land for building block checks
    const onMove = useCallback((event: ThreeEvent<PointerEvent>) => {
        if (!active) {
            return;
        }
        event.stopPropagation();
        setEvent(true, event, base_x, base_y, base_z);
    }, [base_x, base_y, base_z, active, setEvent]);
    const onLeave = useCallback((event: ThreeEvent<PointerEvent>) => {
        event.stopPropagation();
        setEvent(false, event, 0, 0, 0);
    }, [setEvent]);

    // Seed the land based on if it has been claimed by someone or not
    let landContent = <></>;
    if (data && data["ownerName"] !== "") {
        landContent = (
            <>
                <TwitchBillboard channel={data["ownerName"]} active={active}/>
                <Building landX={x} landY={y} x={base_x} y={base_y} z={base_z} active={active}></Building>
            </>
        );
    } else {
        landContent = (
            <>
                <ClaimBoard/>
            </>
        )
    }

    return (
        <group position={[base_x, base_y, base_z]}>
            <RigidBody type="fixed" colliders={false} position={[0, -0.9, 0]}>
                <Box receiveShadow args={[LAND_SIZE, 1, LAND_SIZE]} onPointerMove={onMove} onPointerLeave={onLeave}>
                    <meshStandardMaterial map={texture} map-repeat={[14, 14]} color={active ? "#ADADAD" : "grey"} />
                </Box>
                <CuboidCollider
                    args={[LAND_SIZE / 2.0 - 0.25, 512, LAND_SIZE / 2.0 - 0.25]}
                    sensor
                    onIntersectionEnter={landActivator}
                />
            </RigidBody>
            <Fence></Fence>
            {landContent}
        </group>
    )
}
