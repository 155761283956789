
import {  RigidBody, CuboidCollider } from "@react-three/rapier"
import { Html } from "@react-three/drei"

import disconnected from "../assets/twitch_disconnected.png"
import { TwitchPlayer } from "react-twitch-embed"

export interface TwitchBillboardProps {
    channel: string
    active: boolean
}

export function TwitchBillboard({ active, channel }: TwitchBillboardProps) {

    let showVideo = (
        <div style={{ width: "1280px", height: "720px", position: "relative", textAlign: "center"}}>
            <img style={{}} src={disconnected} alt={`Twitch Channel: {channel}`}>
            </img>
            <p style={{position: "absolute", color: "white", textShadow: "-3px -3px 0 black, 3px -3px 0 black, -3px 3px 0 black, 3px 3px 0 black", fontSize: 80, top: "92%", left: "50%", transform: "translate(-50%, -50%)"}}>
                {channel}
            </p>
        </div>
    );
    if (active) {
        showVideo = (
            <TwitchPlayer
                id={`twitch-player`}
                channel={channel}
                autoplay
                allowFullscreen={false}
                parent={[]}
                width={1280}
                height={720}
                style={{backgroundColor: "black", width: 1280, height: 720}}
            />
        );
    }

    return (
        <RigidBody type="fixed" position={[0.5, 5, 0]}>
            <Html scale={[0.5, 0.5, 0.5]} castShadow occlude="blending" transform>
                {showVideo}
            </Html>
            <CuboidCollider args={[8, 4.5, 0.5]} />
        </RigidBody>
    )
}
