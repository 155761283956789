

import World from '../game/World';

import './game.css';


function Game() {

    return (
        <World></World>
    );
}

export default Game;
