import { create } from 'zustand'

export interface BlockData {
    layers: Record<string, Record<string, number>>;
}

export interface Block {
    ownerId: string;
    ownerName: string;
    blockData: BlockData
};
type State = {
  blocks: Map<string, Block>
}

type Action = {
    setBlock: (x: number, y: number, block: Block) => void
}

// Create your store, which includes both state and (optionally) actions
export const useBlockStore = create<State & Action>((set) => ({
  blocks: new Map<string, Block>(),
  setBlock: (x: number, y: number, block: Block) => set((prev) => ({
        blocks: new Map<string, Block>(prev.blocks).set(`[${x},${y}]`, block)
    })
  ),
}))